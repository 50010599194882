@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
