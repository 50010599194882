@import 'abstracts/_abstracts-dir';

.main {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 0.5em 2em;

  .groupContent {
    display: flex;
    flex-direction: row;
    gap: 28px;
    align-items: center;

    .text {
      cursor: pointer;

      &[aria-checked='true'] {
        color: map-get($info, main);
      }
    }
  }
}