@import 'abstracts/_abstracts-dir';

.main {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  overflow-y: auto;
  gap: 16px;
  padding-bottom: 3em;
  .wrapperItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .wrapperContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;
      @media (max-width: map-get($breakpoints, md)) {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
      }
      .container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .header {
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .wrapperButton {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;

          .cardButton {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0.4em 0.4em;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid map-get($blackWhite, gray200);
            &[aria-checked='true'] {
              background-color: map-get($info, main);
              color: map-get($blackWhite, white);
              border: none;
            }
          }
        }
        .box {
          display: flex;
          gap: 16px;
          .wrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .wrapperHeader {
              display: flex;
              gap: 8px;
            }
          }
        }
      }
    }
  }

  .wrapperBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .group {
      display: flex;
      gap: 24px;
      .box {
        display: flex;
        flex-direction: column;
        .text {
          color: map-get($blackWhite, gray500);
        }
      }
    }
  }
}
