@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  border: 1px solid map-get($blackWhite, gray200);
  padding: 0.5em 1em;

  .text {
    color: map-get($blackWhite, gray700);
  }
  .title {
    text-align: center;
  }

  .wrapperCard {
    display: flex;
    gap: 6px;
    
    .line {
      border-left: 1px solid map-get($blackWhite, gray200);
    }
  }
}
