@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  background: map-get($blackWhite, white);
  padding: 0.5em 1em;
  gap: 8px;
  border-right: 1px solid map-get($blackWhite, gray200);

  .text {
    color: map-get($blackWhite, gray700);
  }
  .wrapperText {
    display: flex;
    flex-direction: column;
    .groupText {
      display: flex;
      gap: 4px;
      color: map-get($success, main);
      &[aria-checked='true'] {
        color: map-get($danger, main);
      }
    }
  }
}
