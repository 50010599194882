@import 'abstracts/variables';

.wapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 240px;
  justify-self: end;
  
  .dateArea {
    position: relative;
    width: 100%;
    
    .customInput {
      border: 1px solid map-get($blackWhite, gray300);
      border-radius: 4px;
      width: 100%;
      // width: calc(100% - 19px - 19px - 2px);
      height: 28px;
      padding: 3px 19px;
    }

    .customInputError {
      border: 1px solid map-get($primary, main);
    }

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      
      width: 20px;
    }
  }
}

.errorMsg {
  text-align: left;
  margin: 0px 14px;
}