@import 'abstracts/variables';

.wrapperContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    height: fit-content;

    .treeRoot {
      height: 100%;
      padding-bottom: 3em;
      padding-left: 24px;
    }

    .draggingSource {
      opacity: 0.3;
    }

    .placeholderContainer {
      position: relative;
    }

    .dropTarget {
      background-color: map-get($info, second);
    }

    .containerDrag {
      align-items: center;
      background-color: map-get($info, main);
      border-radius: 4px;
      display: inline-flex;
      gap: 8px;
      padding: 0.2em;
      color: map-get($blackWhite, white);
      pointer-events: none;
    }

    .rootPlace {
      background-color: map-get($info, main);
      height: 2px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-50%);
    }
  }
}

.rootNode {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 32px;
  padding-inline-end: 8px;
  border-bottom: solid 1px #eee;
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 8px;
}
.root {
  background-color: map-get($info, border);
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}

.treeRoot {
  height: 100%;
}

.draggingSource {
  opacity: 0.3;
}

.placeholderContainer {
  position: relative;
}

.dropTarget {
  background-color: #e8f0fe;
}
