@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
  padding: 1em;
  .header {
    display: flex;
    justify-content: center;
    position: relative;
    img {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
  .wrapperButton {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}
