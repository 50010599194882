@import 'abstracts/_abstracts-dir';

.container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .wrapperBox {
    display: flex;
    gap: 80px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
      transition: opacity linear 0.2s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.footer {
  padding: 2em 0 0 0;
  display: flex;
  justify-content: space-between;
  color: map-get($blackWhite, gray700);
}