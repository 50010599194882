@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  .wrapperGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .boxInput {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 40%;
    }
    .box {
      display: flex;
      gap: 8px;
    }
  }
}
