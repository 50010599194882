@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 1em;

  @media (max-width: map-get($breakpoints, md)) {
    gap: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column-reverse;
      gap: 16px;
    }

    .wrapperButton {
      display: flex;
      gap: 8px;
      @media (max-width: map-get($breakpoints, md)) {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
  .card {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-column-gap: 24px;
    @media (max-width: map-get($breakpoints, md)) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
