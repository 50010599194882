@import 'abstracts/variables';

.container {
  display: flex;
  gap: 16px;
  &[aria-checked='true'] {
    flex-direction: column;
  }

  ::-webkit-scrollbar {
    width: 100%;
    height: 6px;
    background-color: map-get($blackWhite, gray200); /* or add it to the track */
    border-radius: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: map-get($blackWhite, gray300); /* or add it to the track */
  }

  .wrapperDrop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background: map-get($blackWhite, white);
    border: 1px dashed map-get($info, main);
    border-radius: 5px;
    min-width: 118px;
    max-width: 118px;
    min-height: 118px;
    max-height: 118px;
    cursor: pointer;
    color: map-get($info, main);

    &[aria-checked='true'] {
      border: 1.5px dashed map-get($danger, main);
    }

    .wrapperCaption {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    .wrapperCaptionCol {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .box {
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-x: auto;

    .group {
      position: relative;
      .icon {
        cursor: pointer;
        right: 0;
        top: 0;
        padding: 0.2em;
        position: absolute;
      }

      .wrapperImg {
        height: 120px;
        width: 120px;
        border: 1px solid map-get($blackWhite, gray200);
        background-color: map-get($blackWhite, gray100);
        border-radius: 9px;
        overflow: hidden;

        .imgList {
          min-width: 118px;
          max-width: 118px;
          min-height: 118px;
          max-height: 118px;
          object-fit: contain;
          border-radius: 9px;
          cursor: pointer;
          transition: transform .2s; 

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
  }
}
