@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  justify-content: space-between;
  color: map-get($blackWhite, gray400);

  .groupText {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .icon {
      width: 12px;
      height: 12px;
    }
  }
}