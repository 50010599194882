@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
  .leftItem {
    display: flex;
    flex-direction: column;
    width: 45%;
    .photo {
      object-fit: cover;
      max-height: 100vh;
      min-height: 100vh;
    }
  }
  .rightItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;

    .header {
      display: flex;
      padding: 1.5em;
      gap: 18px;
      align-items: center;
      .text {
        color: map-get($blackWhite, gray400);
        cursor: pointer;
      }
      .icon {
        display: flex;
        cursor: pointer;
        gap: 18px;
        border: 1px solid ridge;
        &::after {
          content: '';
          min-height: 100%;
          width: 1px;
          background-color: map-get($blackWhite, gray200);
        }
      }
    }
    .wrapperContainer {
      height: 100%;
      width: 100%;
    }

    .textFooter {
      color: map-get($blackWhite, gray400);
      text-align: right;
      padding: 0.5em;
    }
  }
}
