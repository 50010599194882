@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .wrapperBox {
    display: flex;
    justify-content: space-between;
    .leftBox {
      width: 50%;
      display: grid;
      grid-template-columns: 60% 25% 15%;
      align-items: center;
      gap: 8px;
    }
    .rigthBox {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }
  }
}
