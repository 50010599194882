@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: 20px;
  width: 100%;
  height: 100%;

  .group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 28px;
    .wrapperText {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .iconBelow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 45px;
    img {
      cursor: pointer;
    }
  }
}
