@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .wrapperImg {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    .box {
      display: flex;
      flex-direction: column;
    }
  }
}
