@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .boxText {
    display: flex;
    .text {
      color: map-get($blackWhite, gray500);
      max-width: 162px;
      width: 100%;
    }
    .wrapperChip {
      display: flex;
      gap: 8px;
    }
  }
}
