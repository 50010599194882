@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .card {
    display: flex;
    justify-content: space-between;
  }
}
