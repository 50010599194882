@import 'abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  img {
    width: 58px;
    height: 58px;
    object-fit: contain;
    background-color: map-get($blackWhite, gray100);
    border: 1px solid map-get($blackWhite, gray200);
    border-radius: 8px;
  }
}
