@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapperBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rowCloneOption {
    display: flex;

    p {
      min-width: 200px;
    }

    .wrapper {
      display: flex;
      gap: 10px;
      width: 100%;
      
      button {
        width: 180px;
      }
    }
    
  }
}