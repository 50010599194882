@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0.5em 0.5em 4em 0.5em;
  align-items: center;
  overflow: scroll;

  .contentBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 70%;
    .boxHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .wrapperBox {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .box {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .header {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .wrapperCheck {
              display: flex;
              padding: 0 0.2em;
              border-radius: 5px;
              border: 1px solid map-get($blackWhite, gray200);
              align-items: center;
              gap: 8px;
            }
          }
        }
        .wrapper {
          display: flex;
          gap: 16px;
        }
      }
    }
  }
}
