@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: row;
  background: map-get($blackWhite, white);
  justify-content: space-between;

  .contentGroupBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625em;
    border: 1px solid map-get($blackWhite, gray200);
    width: 100%;

    .textGray {
      margin-left: 24px;
      color: map-get($blackWhite, gray700);
    }

    .textBox {
      margin: 24px 0 0 24px;
    }
    .box {
      display: flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;

      .chartbox {
        display: flex;
        flex-direction: column;
        width: 150px;
        height: 150px;
        margin-left: 24px;
      }
      .valueBox {
        display: flex;
        flex-direction: column;
        width: 100%;

        .colorGroup {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .colorPink {
            width: 20px;
            height: 20px;
            background-color: #d01a6c;
            border-radius: 50%;
          }
          .colorGreen {
            @extend .colorPink;
            background-color: #35b779;
          }

          .colorBlue {
            @extend .colorPink;
            background-color: #66a0fe;
          }
        }
      }
    }
  }
}
