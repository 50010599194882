@import 'abstracts/_abstracts-dir';

.container {
  padding: 1em 0 3em 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrapperCard {
    display: flex;
    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
      gap: 8px;
    }
    .box {
      width: 50%;
      @media (max-width: map-get($breakpoints, md)) {
        width: 100%;
      }
    }
  }
}
