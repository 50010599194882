@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .header {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .groupText {
    display: flex;

    .text {
      color: map-get($blackWhite, gray500);
      max-width: 162px;
      width: 100%;
    }

    p:last-child {
      &[aria-controls='true'] {
        color: map-get($info, main);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
