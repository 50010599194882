@import 'abstracts/_abstracts-dir';

.container {
  padding: 1em 0 3em 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .wrapperForm {
    max-width: 624px;
    width: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        gap: 10px;
      }
    }
  }
}