@import 'abstracts/_abstracts-dir';

.main {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .boxUpLoad {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    .uploadFile {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 20px;
      padding: 0.1em 0.7em;
      color: map-get($info, main);
      border: 1px solid map-get($info, main);
      gap: 5px;
      cursor: pointer;

      .maxPhoto {
        max-width: 16px;
      }
    }
  }

  .noneSeen {
    display: none;
  }
}
