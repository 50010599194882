@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
  padding: 0.5em 2em;
  .wrapperInput {
    display: flex;
    flex-direction: column;
    .box {
      display: flex;
      gap: 8px;
      align-items: flex-end;
    }
  }
}
