@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 10px;

  .form {
    padding: 1em 1.5em;
    .wrapperCard {
      display: flex;
      @media (max-width: map-get($breakpoints, md)) {
        flex-direction: column;
        gap: 8px;
      }
      .box {
        width: 50%;
        @media (max-width: map-get($breakpoints, md)) {
          width: 100%;
        }
      }
    }
  }
}
