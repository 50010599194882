@import 'abstracts/_abstracts-dir';
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 1em;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .icon {
      cursor: pointer;
    }
  }
  .wrapperInput {
    display: flex;
    flex-direction: column;
    gap: 10px;

  }
  .addGroup {
    display: flex;
    justify-content: flex-end;
  }
  .editGroup {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .rowColor {
    display: flex;
    gap: 10px;

    .inputColor {
      height: 37px;
    }
  }
}
