@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .containerCard {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .wrapperInput {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 4em;

      .header {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 24px;
        padding-bottom: 1em;
        border-bottom: 1px solid map-get($blackWhite, gray200);
      }

      .box {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .flexBox {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-column-gap: 24px;
          padding: 1em 0.5em;
          border-bottom: 1px solid map-get($blackWhite, gray200);
          img {
            cursor: pointer;
          }
          &[aria-checked='true'] {
            border-radius: 14px;
            background-color: map-get($primary, focus);
          }
          .wrapper {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            .group {
              display: flex;
              flex-direction: column;
              width: 100%;
              .wrapperCheck {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (max-width: map-get($breakpoints, md)) {
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 3px;
                }
                .buttonDel {
                  cursor: pointer;
                  display: flex;
                  gap: 7px;
                  align-items: center;
                  color: map-get($danger, main);
                }
              }
            }
          }
          .subContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .subBox {
              display: flex;
              align-items: center;
              gap: 8px;
              .groupInput {
                display: flex;
                gap: 8px;
              }
            }
          }

          .seftGridColumnStart {
            grid-column-start: 4;
            margin-top: 10px;

            .addIcon {
              background-color: map-get($info, main);
              color: map-get($blackWhite, white);
              border-radius: 50%;
              cursor: pointer;
            }
          }
        }
      }

      .wrapperButton {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}
