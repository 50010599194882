@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;

  .form {
    padding: 1em 1.5em;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    .card {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 624px;
      width: 100%;
      @media (max-width: map-get($breakpoints, md)) {
        max-width: 500px;
      }

      .wrapperBox {
        display: flex;
        gap: 16px;
        align-items: flex-start;
      }
    }
  }
}
