@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapperBoth {
    display: flex;

    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
      gap: 24px;
    }

    .wrapperBoxText {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 50%;

      .boxText {
        display: flex;

        .text {
          color: map-get($blackWhite, gray500);
          max-width: 162px;
          min-width: 162px;
          width: 100%;
        }

        p:last-child {
          &[aria-controls='true'] {
            color: map-get($info, main);
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .wrapperBox {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 50%;

      .box {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: fit-content;
        width: 100%;

        .header {
          width: fit-content;
        }
      }
    }
  }
}