@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .wrapperButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box {
      width: 30%;
      display: flex;
      align-items: center;
      gap: 8px;
      @media (max-width: map-get($breakpoints, md)) {
        width: 80%;
      }
    }
  }
}
