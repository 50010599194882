@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  padding: 0.5em 1em;
  flex-direction: row;
  justify-content: space-between;
  background-color: map-get($blackWhite, gray100);
  border-bottom: 1px solid map-get($blackWhite, gray200);

  .left {
    display: flex;
    gap: 15px;
    align-items: center;
    .bgBlue {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 44px;
      background-color: map-get($info, second);
      cursor: pointer;
    }
  }
  .right {
    display: flex;
    gap: 20px;
    img {
      cursor: pointer;
    }
  }
  .wrapperInput {
    max-width: 427px;
    width: 100%;
  }
}
