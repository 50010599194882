@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  .wrapperText {
    text-align: center;
  }
  .wrapperInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    gap: 20px;
    @media (max-width: map-get($breakpoints, md)) {
      max-width: 300px;
    }
    .groupText {
      width: 100%;
      .wrapperText {
        display: flex;
        align-items: center;
        gap: 8px;
        color: map-get($danger, main);
        [aria-checked='true'] {
          color: map-get($success, main);
        }
      }
    }
  }
}
