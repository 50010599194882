@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: map-get($blackWhite, white);
  justify-content: space-between;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .wrapperCard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid map-get($blackWhite, gray200);
  }
}
