@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .containerCard {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .wrapperBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .wrapperInput {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .header {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 24px;
        padding-bottom: 1em;
        border-bottom: 1px solid map-get($blackWhite, gray200);
      }
      .box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding-bottom: 1em;
        grid-column-gap: 24px;
        &:not(:last-child) {
          border-bottom: 1px solid map-get($blackWhite, gray200);
        }
        .colBox {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
        }
        .wrapperCheck {
          display: flex;
        }
      }
    }
  }
}
