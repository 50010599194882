@import 'abstracts/_abstracts-dir';
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: grid;
    grid-template-columns: 70% 30%;
  }
}
