@import 'abstracts/variables';

.container {
  display: flex;
  flex-wrap: wrap;
  background: map-get($blackWhite, white);
  width: 100%;
  padding: 0.5em 1em;
  border-bottom: 1px solid map-get($blackWhite, gray200);

  .wrapperPill {
    border-radius: 44px;
    padding: 0.3em 1em;
    text-align: center;

    &[aria-checked='true'] {
      color: map-get($blackWhite, white);
      background: map-get($info, main);
    }
  }
}