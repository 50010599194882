@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;

  .leftItem {
    display: flex;
    flex-direction: column;
    width: 45%;

    .photo {
      object-fit: cover;
      max-height: 100vh;
      min-height: 100vh;
    }
  }

  .rightItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    align-items: center;
    gap: 20px;

    .wrapperInput {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
      gap: 8px;
      @media (max-width: map-get($breakpoints, md)) {
        max-width: 300px;
      }
    }

    .rememberAndForget {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .textColor {
        color: map-get($blackWhite, gray400);
        cursor: pointer;
      }
    }
  }
}
