@import 'abstracts/_abstracts-dir';

.main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .box {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .wrapperHeader {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
      .wrapperInput {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .group {
          display: grid;
          grid-column-gap: 8px;
          grid-template-columns: repeat(4, 1fr);
          padding-bottom: 0.5em;
          border-bottom: 1px solid map-get($blackWhite, gray200);
        }
      }
    }
  }

  .wrapperSelect {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 8px;
  }
}
