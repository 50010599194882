@import 'abstracts/variables';

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 28px;
  padding-right: 1em;

  &[aria-checked='true'] {
    background-color: map-get($blackWhite, gray200);
  }
  &:active {
    background-color: map-get($info, second);
  }

  .icon {
    cursor: grab;
  }

  .wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    
    .expandIconWrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      min-width: 24px;
      justify-content: center;
      transition: transform linear 0.2s;
      transform: rotate(0deg);
      &[aria-expanded='true'] {
        transform: rotate(180deg);
      }
    }

    p {
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
    }
  }
}
