@import 'abstracts/_abstracts-dir';

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  padding: 0.5em 1.5em;
  overflow: auto;
  .wrapperCard {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
