@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1em;
  border-radius: 10px;
  width: 100%;
  min-width: 300px;
  margin-bottom: 30px;
  border: 0.5px solid map-get($blackWhite, gray200);
  &[aria-checked='true'] {
    border: 0.5px solid map-get($primary, main);
  }
  .box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &::after {
      content: '';
      border-top: 1px solid map-get($blackWhite, gray200);
      width: 100%;
    }
    .bothText {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .subText {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .bottomCard {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .groupIcon {
      display: flex;
      gap: 27px;

      img {
        cursor: pointer;
      }
    }
  }
}
