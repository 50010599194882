@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
  .warpperText {
    display: flex;
    text-align: center;
    flex-direction: column;
  }
  .wrapperInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    gap: 20px;
    @media (max-width: map-get($breakpoints, md)) {
      max-width: 300px;
    }
    .selfCenter {
      align-self: center;
    }
  }
}
