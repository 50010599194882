@import 'abstracts/_abstracts-dir';

.gridLayout {
  display: grid;
  grid-template-areas:
    'NavTop NavTop NavTop'
    'Data Data Data'
    'Data Data Data';
  grid-template-columns: 24px max(calc(100% - 48px), calc(768px - 48px)) 24px;
  grid-template-rows: 51px 1fr 1fr;
  min-height: 100vh;
  max-width: 100%;

  .gridNavTop {
    grid-area: NavTop;
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;

  }

  .gridData {
    grid-area: Data;

    .children {
      padding: 15px 24px 0;
    }
  }
}
