@import 'abstracts/variables';

.wapperTable {
  overflow-x: scroll;

  .tableMain {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    tr,
    th,
    td {
      background-color: map-get($blackWhite, white);
      border-top: 1px solid map-get($blackWhite, gray100);
      color: map-get($blackWhite, black);
      padding: 0.3em;
      text-align: left;
      
      &[aria-checked='true'] {
        cursor: pointer;
      }
    }

    tr:first-child td {
      border-top: none;
    }

    tr:hover td {
      background-color: map-get($blackWhite, gray100);
    }

    th {
      border-bottom: 1px solid map-get($blackWhite, black);
    }

    th:first-child,
    td:first-child {
      border-left: none;
    }

    .rowHeadLine {
      background-color: map-get($blackWhite, gray200);
      color: map-get($blackWhite, black);

      &:hover {
        background-color: map-get($blackWhite, gray200);
        color: map-get($blackWhite, black);
      }
    }
  }
}
