@import 'abstracts/variables';

.container {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  background-color: map-get($blackWhite, gray100);
  border: 1px solid map-get($blackWhite, gray200);
  border-radius: 8px;
  width: 120px;
  .text {
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
  }
  img {
    cursor: pointer;
  }
}
