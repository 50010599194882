@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: map-get($blackWhite, white);
  box-shadow: 0px 33px 74px rgba(0, 0, 0, 0.11), 0px 16.9254px 16.5289px rgba(0, 0, 0, 0.0655718),
    0px 5.87201px 4.92108px rgba(0, 0, 0, 0.0444282);
  border-radius: 16px;
  min-width: 400px;
  
  @media (max-width: map-get($breakpoints, md)) {
    width: 90% !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 1em 1.5em;
    border-bottom: 1px solid map-get($blackWhite, gray200);
    .groupLeft {
      display: flex;
      gap: 8px;
      align-items: center;
      .icon {
        display: flex;
        cursor: pointer;
        gap: 4px;
        &::after {
          content: '';
          min-height: 100%;
          width: 1px;
          background-color: map-get($blackWhite, gray200);
        }
      }
    }
  }
}
