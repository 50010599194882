@import 'abstracts/_abstracts-dir';

.main {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .wrapperBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 34px;
  }
  .flexBox {
    display: flex;
    gap: 16px;
    .group {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 30%;
      .wrapperBox {
        display: flex;
        gap: 8px;
      }
    }
    .wrapperInput {
      width: 30%;
    }
  }
  .group {
    max-width: 50%;
  }
  .box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .headerSection {
      display: flex;
      gap: 8px;
    }
  }
}
