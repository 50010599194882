@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: map-get($blackWhite, white);
  justify-content: space-between;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wrapperCard {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    .card {
      display: flex;
      width: 100%;
      border: 1px solid map-get($blackWhite, gray200);
      flex-direction: column;
      gap: 16px;
      max-height: 360px;
      padding: 1em 1em 4em 1em;
      .header {
        display: flex;
        justify-content: space-between;
        .textGray {
          color: map-get($blackWhite, gray700);
        }
        .wrapperButton {
          display: flex;
          align-items: center;
          gap: 8px;
          .cardButton {
            cursor: pointer;
            border-radius: 8px;
            padding: 0.2em 0.5em;
            border: 1px solid map-get($blackWhite, gray200);
          }
        }
      }
    }

    .box {
      display: flex;
      gap: 8px;
      width: 100%;
    }
  }
}
