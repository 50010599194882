@import 'abstracts/variables';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .imgExpanse {
    height: 90%;
    object-fit: cover;
  }
}