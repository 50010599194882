@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  .wrapperInput {
    display: flex;
    gap: 16px;
    justify-content: center;
    .inputOTP {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid map-get($blackWhite, gray200);
      background-color: map-get($blackWhite, gray100);
      padding: 10px;
      text-align: center;
      font-size: toRem(24);
      font-weight: bold;
    }
    .inputOTP:focus {
      border: 2px solid map-get($blackWhite, white);
    }
  }
}
