@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  
  .text {
    color: map-get($danger, main);
  }
}