@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .wrapperButton {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}
