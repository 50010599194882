@import 'abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 4px;
  width: 100%;
  max-width: 160px;
  padding: 0.5em;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  position: absolute;
  background-color: map-get($blackWhite, white);

  .wrapperMenu {
    display: flex;
    gap: 8px;
    padding: 0.3em;
    cursor: pointer;
    &:first-child {
      border-bottom: 1px solid map-get($blackWhite, gray200);
    }
    &:hover {
      background-color: map-get($info, second);
      border-radius: 4px;
    }
  }
}
