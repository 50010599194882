@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: row;
  gap: 100px;
  overflow: auto;
  padding: 1em 6em;

  .leftContainer,
  .rightContainer {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    max-width: 500px;
    gap: 10px;
  }

  .rightContainer {
    flex-basis: 60%;
    max-width: none;
  }

  .wrapperBox {
    display: flex;
    gap: 16px;
    overflow-x: scroll;
  }
}