@import './abstracts/_abstracts-dir';

@font-face {
  font-family: SukhumvitSetRegular;
  src: url('fonts/SukhumvitSet-Text.ttf');
  font-display: swap;
}

@font-face {
  font-family: SukhumvitSetBold;
  src: url('fonts/SukhumvitSet-Bold.ttf');
  font-display: swap;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: SukhumvitSetRegular;
}

a {
  color: inherit;
  text-decoration: none;
}

.fontBold {
  font-family: SukhumvitSetBold !important;
  letter-spacing: 0px !important;
  font-weight: 100 !important;
}

.fontRegular {
  font-family: SukhumvitSetRegular !important;
  letter-spacing: 0px !important;
  font-weight: 100 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: toRem(64);
  line-height: 80px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(32);
    line-height: 50px;
  }
}

h2 {
  font-size: toRem(48);
  line-height: 70px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(27);
    line-height: 40px;
  }
}

h3 {
  font-size: toRem(32);
  line-height: 55px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(22);
    line-height: 30px;
  }
}

h4 {
  font-size: toRem(24);
  line-height: 40px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(18);
    line-height: 30px;
  }
}

h5 {
  font-size: toRem(20);
  line-height: 35px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(16);
    line-height: 20px;
  }
}

h6 {
  font-size: toRem(16);
  line-height: 25px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(14);
    line-height: 20px;
  }
}

.caption {
  font-size: toRem(12) !important;
  line-height: 20px !important;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(11) !important;
    line-height: 15px !important;
  }
}

.textButton {
  font-size: toRem(16) !important;
  line-height: 25px;
  text-decoration: underline;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(14) !important;
    line-height: 20px;
  }
}

.colorRed {
  color: map-get($danger, main);
}

div,
p,
th,
td,
select,
textarea,
select,
option {
  font-size: toRem(14) !important;
  line-height: 25px !important;
  @extend .fontRegular;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: toRem(12) !important;
    line-height: 16px !important;
  }
}

// Start Customize Css Of NotiStack
.SnackbarItem-message {
  align-items: flex-start !important;
  gap: 10px;

  svg {
    margin-top: 4px;
  }
}

.SnackbarItem-variantError {
  border: 2px solid map-get($danger, border) !important;
  background-color: map-get($danger, second) !important;
  color: map-get($blackWhite, gray800) !important;

  p {
    color: map-get($blackWhite, gray500) !important;
  }
}

.SnackbarItem-variantSuccess {
  border: 2px solid map-get($success, border) !important;
  background-color: map-get($success, second) !important;
  color: map-get($blackWhite, gray800) !important;

  p {
    color: map-get($blackWhite, gray500) !important;
  }
}

.SnackbarItem-variantWarning {
  border: 2px solid map-get($warning, border) !important;
  background-color: map-get($warning, second) !important;
  color: map-get($blackWhite, gray800) !important;

  p {
    color: map-get($blackWhite, gray500) !important;
  }
}

.SnackbarItem-variantInfo {
  border: 2px solid map-get($info, border) !important;
  background-color: map-get($info, second) !important;
  color: map-get($blackWhite, gray800) !important;

  p {
    color: map-get($blackWhite, gray500) !important;
  }
}

// End Customize Css Of NotiStack

//Link Text
.linkText {
  cursor: pointer;
  color: map-get($info, main);
  text-align: right;
}

.line {
  border: 1px solid map-get($blackWhite, gray200);
}

//Button Icon
.buttonIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid map-get($blackWhite, gray200);
  border-radius: 5px;
  color: map-get($blackWhite, gray700);
  background-color: map-get($blackWhite, white);
  cursor: pointer;
}

//Custom Scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: map-get($blackWhite, gray100);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: map-get($blackWhite, gray200);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: map-get($blackWhite, gray400);
}

.rectColor {
  width: 25px;
  height: 25px;
  content: ' ';
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

.circleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: map-get($danger, main);
  background-color: map-get($danger, focus);
  width: 30px;
  height: 30px;
}

.gapChips {
  display: flex;
  gap: 5px;
}

.gap20 {
  display: flex;
  gap: 20px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.Mui-disabled,
input[type="text"]:disabled,
input[type="number"]:disabled,
input[type="date"]:disabled,
input[type="password"]:disabled,
input[type="checkbox"]:disabled,
input[type="radio"]:disabled,
select:disabled {
  color: rgba(0, 0, 0, 0.7) !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.7) !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}
