@import 'abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .box {
    display: grid;
    grid-template-columns: 200px calc(100% - 200px);
  }

  .link {
    color: map-get($info, main);
    text-decoration: underline;
    cursor: pointer;
  }
}
