@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: map-get($breakpoints, md)) {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }

    .wrapperGroup {
      display: flex;
      gap: 64px;
      @media (max-width: map-get($breakpoints, md)) {
        flex-wrap: wrap;
      }
      .wrapperText {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .text {
          color: map-get($blackWhite, gray500);
        }
      }
      .wrapperImg {
        display: flex;
        gap: 16px;

        img {
          align-self: center;
          width: 42px;
          height: 42px;
          object-fit: cover;
        }
      }
    }
    .wrapperButton {
      display: flex;
      gap: 8px;
      .text {
        color: map-get($info, main);
      }
    }
  }
}
