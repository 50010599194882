@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  .wrapperCard {
    display: flex;
    gap: 8px;
    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
    }
  }
}
