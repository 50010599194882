@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  gap: 10px;
  align-items: center;

  .textNumber {
    color: map-get($blackWhite, gray400);
  }

  .text {
    color: map-get($blackWhite, gray400);
    
    &[aria-disabled='true'] {
      text-decoration: underline;
      cursor: pointer;
      color: map-get($info, main);
    }
  }
}