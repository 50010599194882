@import 'abstracts/_abstracts-dir';

.main {
  overflow: auto;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px auto;
    padding: 1em 0;
    gap: 40px;
    width: 70%;
  }
}
