@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  .wrapperForm {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0.5em 1.5em;

    .wrapperBox {
      display: flex;
      flex-direction: column;
    }

    .headerTable {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
