@import 'abstracts/variables';

.main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 10;
  width: 513px;
  background-color: map-get($blackWhite, white);
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  justify-content: space-between;

  @media (max-width: map-get($breakpoints, sm)) {
    width: 250px;
  }

  .wrapperBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    .text {
      color: map-get($blackWhite, gray500);
    }
  }

  .formInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .head {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .titleBox {
      display: flex;
      align-items: center;
      gap: 5px;

      .iconAlert {
        width: 24px;
        height: 24px;
      }
    }

    .cross {
      cursor: pointer;
    }
  }

  .body {
    .paddingDetail {
      padding: 0 0 0 1em;
      line-height: 1.6;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
