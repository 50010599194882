@import 'abstracts/_abstracts-dir';
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .headerTop {
    @extend .header;
    padding-bottom: 0.5em;

    border-bottom: 1px solid map-get($blackWhite, gray200);
    .dateInput {
      display: flex;
      width: 233px;
    }
  }
}
