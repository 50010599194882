@import 'abstracts/variables';

.button {
  height: 100%;
  padding: 0.4em 0.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 5px;
  background-color: transparent;
  border: 1px solid map-get($blackWhite, gray200);

}
