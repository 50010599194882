@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 10px;
  .form {
    padding: 1em 1.5em 4em 1.5em;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .card {
      width: 100%;
      @media (max-width: map-get($breakpoints, md)) {
        max-width: 500px;
      }
    }
  }
}


.wrapperProvinceFreight {
  gap: 10px;
  margin-top: 20px;

  .groupAdd {
    color: map-get($info, main);
    cursor: pointer;

    svg {
      width: 18px;
    }
  }

  table, td, th {
    border: 1px solid map-get($blackWhite, gray200);
    text-align: start;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
}