@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapperBoxText {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 16px;

    @media (max-width: map-get($breakpoints, md)) {
      display: flex;
      flex-direction: column;
    }

    .boxText {
      display: flex;

      .text {
        color: map-get($blackWhite, gray500);
        max-width: 162px;
        width: 100%;
      }
    }
  }
}