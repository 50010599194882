@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapperBox {
    display: grid;
    grid-template-columns: 300px calc(100% - 324px);
    grid-column-gap: 24px;
    margin-left: -24px;
    margin-top: -8px;

    @media (max-width: map-get($breakpoints, md)) {
      grid-template-columns: 200px calc(100% - 224px);
    }

    .menuCard {
      display: flex;
      flex-direction: column;
      background-color: map-get($blackWhite, gray100);
      gap: 8px;
      // min-height: calc(100vh - 195px);
      min-height: 100vh;
      
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 0.5em;
        padding-left:  24px;

        @media (max-width: map-get($breakpoints, md)) {
          flex-direction: column-reverse;
          gap: 10px;
        }

        .wrapperButton {
          @media (max-width: map-get($breakpoints, md)) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
          }
        }
      }
    }

    .center {
      margin: auto;
    }
  }
}