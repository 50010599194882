@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapperHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;

    .row {
      display: flex;
      align-items: center;
      gap: 10px;
      
      button {
        height: fit-content;
      }
    }
  }
}