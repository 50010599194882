@import 'abstracts/variables';

.wrapperChip {
  padding: 0 0.5em 0 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: map-get($blackWhite, gray100);
  color: map-get($blackWhite, gray500);
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}
