@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  padding: 0.5em 1.5em;

  .groupAdd {
    color: map-get($info, main);
    cursor: pointer;

    svg {
      width: 18px;
    }
  }

  table, td, th {
    border: 1px solid map-get($blackWhite, gray200);
    text-align: start;
  }
  
  .table {
    border-collapse: collapse;
  }

}
