@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  .wrapperText {
    text-align: center;
  }
}
