@import 'abstracts/_abstracts-dir';

.container {
  padding: 1em 0 3em 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wrapperBox {
    display: flex;
    gap: 8px;
    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
    }
    .box {
      width: 100%;
      display: flex;
      gap: 42px;
      flex-direction: column;
      .group {
        align-items: flex-start;
        display: grid;
        grid-template-columns: 1fr auto;
      }
    }
  }
}
